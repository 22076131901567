<template>
  <div class="container">
    <PublicHeader :totalName="$t('me').Share" />
    <div
      class="share-popup"
      :style="{ backgroundImage: `url(${basicConfig.ShareBackground})` }"
    >
      <p>{{ $t('me').codeText }}</p>
      <div class="cord-box">
        <qrcode-vue
          :value="link"
          :size="size"
          level="H"
          class="qrcode"
        ></qrcode-vue>
        <div>{{ $t('me').ShareText }}</div>
      </div>
      <van-button round type="info" @click="getShareLink">{{
        $t('me').copyLink
      }}</van-button>
    </div>
    <span id="InvitationCode">{{ link }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userApi from '@/api/user'
import QrcodeVue from 'qrcode.vue'
import PublicHeader from '@/components/publicHeader'
export default {
  components: {
    QrcodeVue,
    PublicHeader,
  },
  data() {
    return {
      link: '',
      size: 200,
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      this.userInfo = await userApi.userInfo()
      this.link =
        this.basicConfig.ShareDomain +
        '/#/Register?InvitationCode=' +
        this.userInfo.InvitationCode
    },
    getShareLink() {
      const range = document.createRange()
      range.selectNode(document.getElementById('InvitationCode'))
      const selection = window.getSelection()
      if (selection.rangeCount > 0) selection.removeAllRanges()
      selection.addRange(range)
      document.execCommand('copy')
      selection.removeRange(range)
      this.$toast({
        type: 'success',
        message: '复制成功',
        className: 'message',
        duration: '1000',
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.share-popup
    height calc(100vh - 49px)
    padding-top 10vh
    margin-top 49px
    color #fff
    // height 540px
    background #1f1f1f
    text-align center
    background-size 100% 100%
    .close
      flex-column()
      z-index 9
      font-size 14px
      line-height 1.5
    img
      height 350px
      position absolute
      left 0
      top 0
      z-index -1
    p
      font-weight bold
      font-size 19px
      margin 38px 0 26px
    .cord-box
      border-radius 10px
      box-shadow 0 0 5px #fff
      background #ffffff
      width 250px
      padding 10px
      height 290px
      color #000
      font-size 23px
      margin  0 auto
      flex-column()
      div
        margin-top 15px
    .van-button
      width 300px
      height 44px
      background: linear-gradient(to right, rgb(248, 94, 92), rgb(254, 188, 41));
      border: 0px;
      margin-top 40px
  #InvitationCode
    position absolute
    left -100vw
</style>
